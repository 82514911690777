import flatpickr from "flatpickr"
const German = require("flatpickr/dist/l10n/de.js").default.de;

import 'select2';

const initflatpickr = () => {
  flatpickr("[data-behavior='flatpickr']", {
    "locale": German,
    allowInput: true,
    altInput: true,
    altFormat: "j. F Y",
    dateFormat: "Y-m-d"
  })
}

export { initflatpickr };
